import { FC } from 'react';
import { CircularProgress, Box } from '@mui/material';
import './style.scss'

type CircleType = 'bar' | 'circle'
interface SpinnerProps {
  height?: string;
  type?: CircleType;
  size?: string | number;
}

const GlobalSpinner: FC<SpinnerProps> = ({ height = '100vh', size }) => {
  return (
    <Box data-testid="global-spinner" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: height }}>
      <CircularProgress color="primary" size={size} />
    </Box>
  );
};

// inside button
export const GlobalLoadingSpinner: FC<SpinnerProps> = ({type = 'circle'}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {type === 'circle' && <CircularProgress sx={{color: '#515326', height: 20}} />}
      {type === 'bar' && <Box className='bar_style'/>}
    </Box>
  );
};

export const GlobalBarLoader = () => {
  return <Box className='bar_style'/>
}

export default GlobalSpinner;
