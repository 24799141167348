import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import Close from '../../assets/images/close.svg';
import Error from '../../assets/images/error.svg';
import Onboard from '../../assets/images/onboard.svg';
import Success from '../../assets/images/success.svg';

interface AlertComponenetProps {
  msg?: string;
  hideHeading?: boolean;
  toastType?: 'onboard' | 'error' | 'success';
}
const AlertComponenet: FC<AlertComponenetProps> = ({
  msg,
  hideHeading = false,
  toastType = 'error'
}) => {
  return (
    <div>
      <Box
        sx={{
          borderRadius: '8px',
          border: '2px solid rgba(221, 255, 85, 0.08)',
          background: '#22261C',
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          padding: '20px 16px',
          width: '100%'
        }}>
        <Box
          sx={{
            width: 38,
            height: 38,
            padding: 1,
            backgroundColor: 'rgba(221, 255, 85, 0.20)',
            borderRadius: '99px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          {toastType === 'error' && <img src={Error} alt="error" />}
          {toastType === 'onboard' && <img src={Onboard} alt="onboard" />}
          {toastType === 'success' && <img src={Success} alt="Success" />}
        </Box>
        <Box>
          {!hideHeading && (
            <Typography variant="h2" gutterBottom fontSize={20} lineHeight="28px" mb={1}>
              Oops! Something went wrong...
            </Typography>
          )}
          {msg && (
            <Typography variant="body2" gutterBottom fontSize={16} lineHeight="24px" mb={0}>
              {String(msg)}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            cursor: 'pointer',
            marginLeft: 'auto'
          }}>
          <img src={Close} alt="X" />
        </Box>
      </Box>
    </div>
  );
};

export default AlertComponenet;
