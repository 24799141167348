import { Box, Checkbox, Grid, Typography, Tooltip } from '@mui/material';
import { Permissions } from 'features/accounts/types';
import { FC } from 'react';
import audit from '../assets/audit.svg';
import scaler from '../assets/scaler.svg';
import storage from 'utils/storage';
import CustomToolTip from 'components/StylesTooltip';

interface StepOneProps {
  permission: Permissions;
  setPermission: (e: any) => void;
  hideAudit?: boolean;
}

const StepOne: FC<StepOneProps> = ({ permission, setPermission, hideAudit }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    if (name === 'AUDIT') {
      setPermission((prev: any) => ({ ...prev, ['AUDIT']: !permission?.AUDIT }));
      storage.setAccountsPermission({ ...permission, AUDIT: !permission?.AUDIT });
    } else {
      setPermission((prev: any) => ({ ...prev, ['AUTO_SCALER']: !permission.AUTO_SCALER }));
      storage.setAccountsPermission({ ...permission, AUTO_SCALER: !permission.AUTO_SCALER });
    }
  };
  return (
    <Grid container spacing={2} marginTop={0.5} maxWidth={'1035px'} sx={{ mx: 'auto' }}>
      <Grid item xs={1.5} sx={{ alignItems: 'center', display: 'flex' }}>
        <Box
          sx={{
            background: 'rgba(221, 255, 85, 0.08)',
            border: '1px solid rgba(221, 255, 85, 0.08)',
            borderRadius: '12px',
            padding: '8px 16px',
            width: '99px',
            textAlign: 'center'
          }}>
          <Typography
            variant="body2"
            gutterBottom
            fontSize={18}
            lineHeight="24px"
            mb={0}
            color="primary">
            Step 1
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={10.5}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: 'rgba(255, 255, 255, 0.05)',
            backdropFilter: 'blur(24.5px)',
            padding: '24px',
            borderRadius: '16px'
          }}>
          <Box>
            <Box sx={{ position: 'relative', width: 470 }}>
              <Typography variant="h5" gutterBottom fontSize={20} lineHeight="28px" mb={1.8}>
                Select product(s) you want grant permission to
              </Typography>
              <CustomToolTip
                top={5}
                text="These permissions allow Lucidity to manage disks attached to a VM."
              />
            </Box>
            <Box sx={{ display: 'flex', gap: '16px', position: 'relative', height: 120 }}>
              <CustomToolTip
                showIcon={false}
                left={0}
                right={250}
                text="Lucidity Auto-scaler allows the customer to scale storage dynamically and automatically depending on the workload. ">
                <Box
                  sx={{
                    width: 154,
                    height: 100,
                    background: 'rgba(255, 255, 255, 0.05)',
                    borderRadius: '16px',
                    textAlign: 'center',
                    position: 'relative',
                    border: permission.AUTO_SCALER
                      ? '1px solid #DDFF55'
                      : '1px solid rgba(255, 255, 255, 0.05)'
                  }}>
                  <Checkbox
                    sx={{ position: 'absolute', right: 0, top: 0 }}
                    name="AUTO_SCALER"
                    checked={permission?.AUTO_SCALER}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Box
                    sx={{
                      width: 32,
                      height: 32,
                      background: 'rgba(255, 255, 255, 0.1)',
                      borderRadius: 99,
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      flexWrap: 'wrap',
                      margin: '16px auto 12px auto'
                    }}>
                    <img height={15} src={scaler} alt="scaler" />
                  </Box>
                  <Typography variant="body2" fontSize={16} lineHeight="24px" mb={0}>
                    Auto Scaler
                  </Typography>
                </Box>
              </CustomToolTip>

              {!hideAudit && (
                <CustomToolTip
                  showIcon={false}
                  left={180}
                  right={100}
                  text="Lucidity Audit is an automated disk metrics collection tool.">
                  <Box
                    sx={{
                      width: 154,
                      height: 100,
                      background: 'rgba(255, 255, 255, 0.05)',
                      borderRadius: '16px',
                      textAlign: 'center',
                      position: 'relative',
                      border: permission.AUDIT
                        ? '1px solid #DDFF55'
                        : '1px solid rgba(255, 255, 255, 0.05)'
                    }}>
                    <Checkbox
                      sx={{ position: 'absolute', right: 0, top: 0 }}
                      name="AUDIT"
                      checked={permission?.AUDIT}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Box
                      sx={{
                        width: 32,
                        height: 32,
                        background: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: 99,
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        flexWrap: 'wrap',
                        margin: '16px auto 12px auto'
                      }}>
                      <img height={15} src={audit} alt="scaler" />
                    </Box>
                    <Typography variant="body2" fontSize={16} lineHeight="24px" mb={0}>
                      Audit
                    </Typography>
                  </Box>
                </CustomToolTip>
              )}
            </Box>
          </Box>
          <Box></Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default StepOne;
