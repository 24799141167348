import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';
import {
  ProductPoliciesReq,
  ProductPoliciesRes,
  ListCloudEntitiesRequest,
  ListCloudEntitiesResponse,
  CloudEntitySetupRequest,
  CloudEntitySetupResponse
} from '../types';

export const getProductPolicies = (
  payload: ProductPoliciesReq
): Promise<ApiResponse<ProductPoliciesRes>> =>
  ApiService.put(`/api/v1/getProductPolicies`, payload);

export const listCloudEntitiesApi = (
  payload: ListCloudEntitiesRequest
): Promise<ApiResponse<ListCloudEntitiesResponse>> =>
  ApiService.put(`/api/v1/listCloudEntities`, payload);

export const cloudEntityOnboardApi = (
  payload: CloudEntitySetupRequest
): Promise<ApiResponse<CloudEntitySetupResponse>> => ApiService.put(`/api/v1/cloudEntityOnboard`, payload);
