import { createSlice } from '@reduxjs/toolkit';
// local import
import { CloudProviderTypeSlice } from '../types';

const initialState: CloudProviderTypeSlice = {
  cloudType: '',
  permissions: {AUDIT: false, AUTO_SCALER: false},
  setup: undefined,
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setCloudProvider: (state, action) => {
      state.cloudType = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setSetUpRes: (state, action) => {
      state.setup = action.payload;
    }
  }
});

export const { setCloudProvider, setPermissions, setSetUpRes } = accountsSlice.actions;

export default accountsSlice.reducer;
