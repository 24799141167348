import { useMsal } from '@azure/msal-react';
import { Box, Grid, Typography } from '@mui/material';
import { setCloudProvider, setPermissions } from 'features/accounts/store/accounts.slice';
import { Permissions } from 'features/accounts/types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { isUndefineNull } from 'utils/common';
import storage from 'utils/storage';
import ListCloudEntities from '../../shared/components/ListCloudEntities';
import StepOne from '../../shared/components/StepOne';
import StepTwo from '../../shared/components/StepTwo';
import '../assets/style.scss';
import StepThree from './StepThree';
import toast from 'react-hot-toast';

const AzureContainer = () => {
  const { inProgress } = useMsal();
  const dispatch = useAppDispatch();
  const [permission, setPermission] = useState<Permissions>({ AUTO_SCALER: true, AUDIT: true });
  const [tokenLoaded, setTokenLoaded] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setCloudProvider('AZURE'));
    if (storage.getGraphToken() && storage.getManagentToken()) {
      setTokenLoaded(true);
    }
  }, []);

  useEffect(() => {
    dispatch(setPermissions(permission));
  }, [permission]);

  useEffect(() => {
    const gToken = isUndefineNull(storage.getGraphToken());
    const mToken = isUndefineNull(storage.getManagentToken());
    if (!gToken && !mToken) {
      setTokenLoaded(true);
    }
    if (!mToken && gToken) {
      toast.error('Failed to get Graph Token');
    }
    if (mToken && !gToken) {
      toast.error('Failed to get Management Token');
    }
  }, [inProgress]);

  return (
    <>
      {!tokenLoaded && (
        <>
          <Grid container spacing={2} marginTop={0.5} maxWidth={'1035px'} sx={{ mx: 'auto' }}>
            <Grid item xs={1.5}></Grid>
            <Grid item xs={10} sx={{ alignItems: 'center' }}>
              <Typography variant="h3" gutterBottom fontSize={36} lineHeight="42px" mb={0.5}>
                Connect your Azure Account
              </Typography>
              <Typography variant="body1" gutterBottom fontSize={16} lineHeight="22px" mb={0}>
                There is no access to your company’s Azure data.
              </Typography>
            </Grid>
          </Grid>
          <StepOne permission={permission} setPermission={setPermission} />
          <StepTwo />
          <StepThree />
        </>
      )}
      {tokenLoaded && <ListCloudEntities />}
      <Box
        sx={{
          display: 'flex',
          gap: '2px',
          bottom: 20,
          width: '90%',
          position: 'fixed',
          justifyContent: 'center',
          background: '#161718',
          padding: '10px'
        }}>
        <Typography variant="body1" gutterBottom fontSize={16} lineHeight="22px" mb={0}>
          Wan’t to add account manually?
        </Typography>
        <Link to="/accounts/download?code=azure" className="download">
          Download Instructions PDF
        </Link>
      </Box>
    </>
  );
};

export default AzureContainer;
