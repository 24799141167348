import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import React, { FC, Suspense } from 'react';
// local import
import GlobalSpinner from 'components/GlobalSpinner';
import { DRAWERWIDTH } from 'constants/pages';
import LeftNav from './LeftNav';
import './style.scss';
import TopNav from './TopNav';
import { useAppSelector } from '../../store/hooks';

const drawerWidth = DRAWERWIDTH;

type AppProviderProps = {
  children?: React.ReactNode;
};

const Layout: FC<AppProviderProps> = ({ children }) => {
  const { tenantData } = useAppSelector((state) => state.tenants);
  return (
    <Box sx={{ display: 'flex' }} className="navbar">
      <CssBaseline />
      <TopNav tenantData={tenantData}/>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }}}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              alignItems: 'center',
              py: 3.8,
              backgroundColor: 'rgba(31, 32, 35, 0.28)',
              backdropFilter: 'blur(32.5px)',
              border: 'none',
              overflowY: 'hidden' 
            }
          }}
          open
        >
          <LeftNav />
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3.50, pt: 10, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Suspense fallback={<GlobalSpinner />}>{children}</Suspense>
      </Box>
    </Box>
  );
};

export default Layout;
