import { createSlice } from '@reduxjs/toolkit';
// local import
import { DateTenantFilter } from 'types/dateTenantFilter.model';

const initialState: DateTenantFilter = {
  requestFilter: {
    filters: [
      {
        name: '1D',
        id: '1D',
        applied: false,
        value: null
      },
      {
        name: '7D',
        id: '7D',
        applied: false,
        value: null
      },
      {
        name: '30D',
        id: '30D',
        applied: false,
        value: null
      },
      {
        name: 'MTD',
        id: 'MTD',
        applied: false,
        value: null
      },
      {
        name: 'YTD',
        id: 'YTD',
        applied: false,
        value: null
      },
      {
        name: 'CUSTOM',
        id: 'CUSTOM',
        applied: false,
        value: {
          fromTimestamp: undefined,
          toTimestamp: undefined
        }
      },
      {
        name: 'TENANT',
        id: 'TENANT',
        applied: true,
        value: {
          tenantNames: undefined
        }
      }
    ],
    pageNo: 0,
    pageSize: 5
  },
  onboardMeta: {
    region: '',
    currentSelectedCloudCode: undefined,
    tenantId: '',
    cloudEntityName: '',
    dashboardFriendlyName: '',
    gcpRegion: ''
  }
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    filters: (state, action) => {
      if (action.payload.pageNo || action.payload?.pageNo === 0) {
        state.requestFilter.pageNo = action.payload.pageNo;
      } else {
        state.requestFilter.filters.forEach((itm: any) => {
          if (action.payload.date && itm.name !=='TENANT') {
            if (itm.name === action.payload.date) {
              itm.applied = true;
              if (itm.name === 'CUSTOM') {
                itm.value = action.payload.custom;
              }
            } else {
              itm.applied = false;
            }
          } else if (itm.name === 'TENANT' && action.payload.tenants) {
            itm.value.tenantNames = action.payload.tenants;
          }
        });
      }
    },
    resetPag: (state) => {
      state.requestFilter.pageNo = 0;
    },
    resetTenant: (state) => {
      state.requestFilter.filters[6].value.tenantNames = undefined;
      state.onboardMeta = { ...state.onboardMeta, tenantId: '' };
    },
    setOnboardMeta: (state, action) => {
      state.onboardMeta = { ...state.onboardMeta, ...action.payload };
    },
    resetOnboardMeta: (state) => {
      state.onboardMeta = { region: '',
        currentSelectedCloudCode: undefined,
        tenantId: '',
        cloudEntityName: '',
        dashboardFriendlyName: '',
        gcpRegion: '' };
    }
  }
});

export const { filters, resetPag, resetTenant, setOnboardMeta, resetOnboardMeta } = filterSlice.actions;

export default filterSlice.reducer;
