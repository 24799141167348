import { createSlice } from '@reduxjs/toolkit';
// local import
import { SearchSortSliceType } from '../types';

const initialState: SearchSortSliceType = {
  isSearchActive: false,
  searchQuery: '',
  sortQuery: ''
};

const searchSortSlice = createSlice({
  name: 'searchsort',
  initialState,
  reducers: {
    searchSort: (state, action) => {
      Object.assign(state, action.payload);
    },
    searchSortActive: (state) => {
      state.isSearchActive = true;
    },
    resetSearchSort: (state, action) => {
      state.isSearchActive = false;
      if (action.payload.search) {
        state.searchQuery = '';
      } 
      if (action.payload.sort) {
        state.sortQuery = '';
      } 
      else if(action.payload.both) {
        state.sortQuery = '';
        state.searchQuery = '';
      }
    }
  }
});

export const { searchSort, searchSortActive, resetSearchSort } = searchSortSlice.actions;

export default searchSortSlice.reducer;
